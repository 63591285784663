export default function getVideoMes(uploadFile) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video'); // 创建video节点
    video.src = URL.createObjectURL(uploadFile.raw);
    video.addEventListener('loadedmetadata', () => {
      resolve({
        videoWidth: video.videoWidth,
        videoHeight: video.videoHeight,
        isVideoH264: !!video.canPlayType('video/mp4; codecs="avc1.64001E"'),
        duration: video.duration,
      });
    });
    video.addEventListener('error', (e) => {
      reject();
    });
  });
}
