export default function getImageSize(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function () {
      reject(null);
    };
    img.src = url;
  });
}
