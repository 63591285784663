import request from '@/utils/request';
import axios from 'axios';
const SNAPCHAT = process.env.VUE_APP_SNAPCHAT_AUTOGOODS;
const GIAMCOMMON = process.env.VUE_APP_OPERATE;
// 获取枚举常量
export function getEnumConstants() {
  return request({
    baseURL: SNAPCHAT,
    url: `/snap/ads/getEnumConstants`,
    method: 'get',
  });
}

// 树形地区
export function queryTreeCountry() {
  return request({
    baseURL: SNAPCHAT,
    url: '/targeting/queryTreeCountry',
    method: 'get',
  });
}

// 预设受众
export function queryInterestByCountry(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/targeting/queryInterestByCountry',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 生成广告层级预览
export function createAdPreview(data, that) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/createAdPreview',
    method: 'post',
    data,
    cancelToken: new axios.CancelToken(function executor(c) {
      // 设置 cancel token
      // console.log(c);
      if (that) {
        that.source = c;
      }
    }),
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 上传广告
export function uploadAd(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/uploadAd',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 获取受众规模
export function getAudienceSize(data, params) {
  return request({
    baseURL: SNAPCHAT,
    url: '/targeting/getAudienceSize',
    data,
    params,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 删除兴趣词
export function interestLabelDel(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/targeting/interestLabelDel',
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
